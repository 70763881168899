<!--
 * @Author: chenxing
 * @Date: 2021-08-06 16:22:24
 * @LastEditors: chenxing
 * @LastEditTime: 2021-08-23 16:15:28
-->
<template>
  <div class="layout-content-container">
    <div class="filter-container">
      <label class="page-header">任务中心</label>
      <a-divider></a-divider>
      <a-row style="margin-bottom: 8px">
        <a-col :span="1">
          <label style="height: 100%" class="flexAlignCenter flexJustifyCenter">筛选：</label>
        </a-col>
        <a-col :span="4">
          <a-select placeholder="任务类型" style="width: 90%" v-model:value="filterCondition.pushType">
            <a-select-option v-for="type in taskType" :key="type.text" :value="type.value">{{ type.text }}</a-select-option>
          </a-select>
        </a-col>
        <!-- <a-col :span="3">
          <a-input placeholder="创建人(精确匹配)" style="width: 90%" v-model:value="filterCondition.creator"> </a-input>
        </a-col> -->
        <a-col :span="3">
          <a-select placeholder="任务状态" style="width: 90%" v-model:value="filterCondition.status">
            <a-select-option v-for="status in taskStatus" :key="status.value" :value="status.value">{{ status.text }}</a-select-option>
          </a-select>
        </a-col>
        <!-- <a-col :span="1">
        <label style="height: 100%" class="flexAlignCenter flexJustifyCenter">创建日期：</label>
      </a-col> -->
        <a-col :span="3">
          <a-date-picker v-model:value="filterCondition.createTime" placeholder="创建日期" :disabled-date="disabledDate" @change="onCreateTimeChange"></a-date-picker>
        </a-col>
        <!-- <a-col :span="4" :push="1">
        <a-input placeholder="请输入搜索内容" size="default" v-model:value="filterCondition.searchKey">
          <template #prefix>
            <SearchOutlined />
          </template>
          <template #addonAfter>
            <a-select style="width: 80px" v-model:value="filterCondition.searchField">
              <a-select-option value="id">任务ID</a-select-option>
            </a-select>
          </template>
        </a-input>
      </a-col> -->
        <a-col :span="2" :push="1">
          <a-space style="float: right">
            <a-button type="primary" :loading="tableLoading" @click="onFilterClick">筛选</a-button>
            <a-button @click="onResetClick">重置</a-button>
          </a-space>
        </a-col>
      </a-row>
    </div>

    <div class="table-container">
      <div class="btn-container">
        <a-button type="primary" :disabled="batchRetryDisabled" :loading="taskRetring" @click="batchRetryTask">批量重试</a-button>
      </div>

      <datatable :cols-setting="tableColumns" :data-source="tableData" row-key="id" :pagination="tablePagination" :table-loading="tableLoading" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onTableRowSelectChange, columnWidth: 1, getCheckboxProps: tableCheckboxProps }" @table-change="onTablePaginationChange">
        <template #status="{ record }">
          <ClockCircleFilled v-if="record.taskStatus == 0" style="color: #da8932; font-size: 1.2em" />
          <CheckCircleFilled v-if="record.taskStatus == 1" style="color: #28af6a; font-size: 1.2em" />
          <CloseCircleFilled v-if="record.taskStatus == 2" style="color: #da3232; font-size: 1.2em" />
          {{ getText(record.taskStatus, taskStatus) }}
          <a-tooltip :title="record.reason">
            <QuestionCircleFilled v-if="record.taskStatus == 2" style="color: #65a5e3" />
          </a-tooltip>

<!--          <a v-if="record.taskStatus == 2" style="float: right" @click="retrytask(record.id)">重试</a>-->
        </template>
        <template #detail="{ record }">
          <div v-if="record.pushType == '素材推送' " style="display: flex">
            <div style="display: flex; flex-direction: column; text-align: right; margin-right: 0.5em">
              <label>素材ID:</label>
              <label>媒体:</label>
              <label>账号ID:</label>
            </div>
            <div style="display: flex; flex-direction: column">
              <label>{{ JSON.parse(record.detail).materialId }}</label>
              <label>{{ getText(JSON.parse(record.detail).mediaChannel, channelList) }} </label>
              <label>{{ JSON.parse(record.detail).advertiserId }}</label>
            </div>
          </div>
          <div v-if="record.pushType == '账号复制' " style="display: flex">
            <div style="display: flex; flex-direction: column;  margin-right: 0.5em">
              <label>账号ID：{{ JSON.parse(record.detail).copyAccountId }}</label>
              <label>账户名称前缀：{{ JSON.parse(record.detail).copyName }}</label>
              <label>账户起始序列号：{{ JSON.parse(record.detail).startNumber }}</label>
              <label>复制数量：{{ JSON.parse(record.detail).copyNumber }}</label>
              <label>自运营报备类型：{{ getText(JSON.parse(record.detail).reportType, reportTypeList) }}</label>
              <label>{{ record.msg }}</label>
              <label>{{ record.reason }}</label>
            </div>
          </div>
        </template>

        <template #op="{ record }">
          <a v-if="record.taskStatus == 2  && record.pushType == '素材推送' "  @click="retrytask(record.id)">重试</a>
          <a v-if="record.taskStatus == 0 && record.pushType == '账号复制' "  @click="retrytask(record.id)">停止</a>
        </template>
      </datatable>
    </div>
  </div>
</template>

<script>
import datatable from '@/components/Customize/table.vue';
import { CheckCircleFilled, CloseCircleFilled, ClockCircleFilled, QuestionCircleFilled } from '@ant-design/icons-vue';
import { getTaskListData, retryTask } from '@/api/center/task.js';
import { channelList, reportTypeList } from '@/config/globalData.js';
import moment from 'moment';

export default {
  components: {
    datatable,
    CheckCircleFilled,
    CloseCircleFilled,
    ClockCircleFilled,
    QuestionCircleFilled,
  },
  data() {
    return {
      channelList: channelList,
      reportTypeList: reportTypeList,
      tableLoading: false,
      taskRetring: false,
      selectedRowKeys: [],
      filterCondition: {},
      tableData: [],
      taskStatus: [
        { text: '进行中', value: 0 },
        { text: '成功', value: 1 },
        { text: '失败', value: 2 },
        { text: '已停止', value: 3 },
      ],
      taskType: [{ text: '素材推送', value: '素材推送' },{ text: '账号复制', value: '账号复制' }],
      tableColumns: [
        {
          title: '任务状态',
          dataIndex: 'taskStatus',
          key: 'status',
          width: 100,
          sortDirections: ['descend', 'ascend'],
          slots: {
            customRender: 'status',
          },
        },
        {
          title: '任务类型',
          dataIndex: 'pushType',
          key: 'pushType',
          width: 100,
        },
        {
          title: '明细',
          dataIndex: 'detail',
          key: 'detail',
          width: 100,
          slots: {
            customRender: 'detail',
          },
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
          width: 100,
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: '创建人',
          dataIndex: 'operatorName',
          key: 'creator',
          width: 100,
        },
        {
          title: '任务ID',
          dataIndex: 'batchNo',
          key: 'batchNo',
          width: 100,
        },
        {
          title: '操作',
          dataIndex: 'op',
          key: 'op',
          width: 100,
          slots: {
            customRender: 'op',
          },
        },
      ],
      tablePagination: {
        limit: '50',
        page: '1',
        pageSize: 50,
        showTotal: total => `总计${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50'],
      },
    };
  },
  computed: {
    batchRetryDisabled() {
      return this.selectedRowKeys.length == 0;
    },
  },
  created() {
    this.getTableData();
  },
  methods: {
    disabledDate(current) {
      return current > moment().endOf('day');
    },
    getText(value, array) {
      if (value != null || value > -1) {
        return array.find(item => {
          return item.value == value;
        }).text;
      }
    },
    getTableData() {
      this.tableLoading = true;
      getTaskListData({
        taskStatus: this.filterCondition.status,
        pushType: this.filterCondition.pushType,
        operatorName: this.$store.state.oauth.userInfo.userCode,
        createTime: this.filterCondition.createTime,
        limit: this.tablePagination.limit,
        page: this.tablePagination.page,
      }).then(
        res => {
          if (res.code === 0) {
            this.tableData = res.page.records;
            this.tablePagination.total = res.page.total;
            this.tableLoading = false;
          }
        },
        error => {
          this.tableLoading = false;
        }
      );
    },
    onCreateTimeChange(val, dateString) {
      this.filterCondition.createTime = dateString;
    },
    onTableRowSelectChange(selectKeys, selectedRows) {
      this.selectedRowKeys = selectKeys;
    },
    tableCheckboxProps(record) {
      return {
        disabled: record.taskStatus !== 2,
      };
    },
    onTablePaginationChange(pagination) {
      this.tablePagination.pageSize = pagination.pageSize;
      this.tablePagination.limit = pagination.limit;
      this.tablePagination.page = pagination.page;
      this.getTableData();
    },
    async retrytask(taskId) {
      if (this.taskRetring) {
        return;
      }

      this.retryTaskRequest([taskId], () => {
        this.tableData.find(d => d.id == taskId).taskStatus = 0;
      });
    },
    async batchRetryTask() {
      if (this.taskRetring) {
        return;
      }

      this.retryTaskRequest(this.selectedRowKeys, () => {
        this.tableData.forEach(d => {
          let index = this.selectedRowKeys.findIndex(k => k == d.id);
          if (index > -1) {
            d.taskStatus = 0;
            this.selectedRowKeys.splice(index, 1);
          }
        });
      });
    },
    async retryTaskRequest(taskIds, success) {
      this.taskRetring = true;
      let res = await retryTask(taskIds);
      if (res.code === 0) {
        success && success();
      }
      this.taskRetring = false;
    },
    onFilterClick() {
      this.getTableData();
    },
    onResetClick() {
      this.filterCondition = {};
      this.getTableData();
    },
  },
};
</script>

<style lang="less" scoped>
.layout-content-container {
  margin: 0 ~`toRem(228) `;
}

.filter-container {
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  display: inline-block;

  .page-header {
    font-size: 0.5rem;
    font-weight: bold;
    margin: 0 0.3rem 0.1rem 0.3rem;
    display: block;
  }

  :deep(.ant-divider) {
    width: 110%;
    left: -1rem;
    top: -0.15rem;
    display: inline-block;
  }

  :deep(.ant-input-affix-wrapper),
  :deep(.ant-calendar-picker-input),
  :deep(.ant-input) {
    height: 32px;
  }

  :deep(.ant-input-affix-wrapper) .ant-input {
    height: initial;
  }
}

.table-container {
  padding: 0.25rem;
  background: #ffffff;
  border-radius: 8px;

  .btn-container {
    margin: 0.25rem 0 0.5rem 0;
  }
}
</style>