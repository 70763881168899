/*
 * @Author: chenxing
 * @Date: 2021-08-13 14:06:46
 * @LastEditors: chenxing
 * @LastEditTime: 2021-08-13 18:46:37
 */
import axios from '@/utils/axios';

const apiUrl = {
    taskList: '/task/get/push/material/result',
    retryTask: '/task/retry'
};

// 获取菜单列表
export function getTaskListData(params) {
    return axios.post(apiUrl.taskList, params);
}

// 获取菜单列表
export function retryTask(params) {
    return axios.post(apiUrl.retryTask, params);
}